<template>
  <VContainer class="main--body--content">
    <div class="back-menu align-center">
      <a
        @click="$router.go(-1)"
        href="javascript:void('back')"
        :to="{ name: 'Docs' }"
      >
        <VIcon class="mr-2" dense>fa-caret-left</VIcon>
        <span>Back</span>
      </a>
    </div>

    <form class="form" @submit.prevent="newArticle">
      <VRow>
        <VCol cols="6">
          <VAutocomplete
            v-model="form.category_id"
            :items="getCategoriesDropdown"
            item-value="id"
            item-text="title"
            label="Choose a category"
            outlined
            dense
          ></VAutocomplete>
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            label="Title"
            placeholder="Enter the title"
            v-model="form.title"
            :required="true"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            label="Description"
            placeholder="Enter the description, it will help better searcb"
            v-model="form.description"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            outlined
            dense
            type="number"
            label="Position"
            placeholder="1.0000000"
            v-model.number="form.position"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <InputImage
            title="File Uploader"
            placeholder="https://uploadcare.com/jasf9028qitws/asfl;asf/3000x3000/light"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <h3 class="mb-2">GemPages Markdown Editor</h3>
          <div :id="id" class="gem-md-editor" />
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12">
          <div class="d-flex align-center justify-center">
            <VBtn color="primary" type="submit">Create</VBtn>
          </div>
        </VCol>
      </VRow>
    </form>
  </VContainer>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import docsAPI from "@/api/internal/docs.js";

import "@toast-ui/editor/dist/toastui-editor.css";
import Editor from "@toast-ui/editor";
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'prismjs/themes/prism.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import defaultOptions from "./default-options";

export default {
  name: "ArticleNew",
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default() {
        return (
          "markdown-editor-" +
          +new Date() +
          ((Math.random() * 1000).toFixed(0) + "")
        );
      }
    },
    options: {
      type: Object,
      default() {
        return defaultOptions;
      }
    },
    mode: {
      type: String,
      default: "markdown"
    },
    height: {
      type: String,
      required: false,
      default: "600px"
    },
    language: {
      type: String,
      required: false,
      default: "en_US" // https://github.com/nhnent/tui.editor/tree/master/src/js/langs
    }
  },
  data() {
    return {
      form: {
        category_id: null,
        title: null,
        description: null,
        position: null
      },
      dataForm: "",
      editor: null
    };
  },
  computed: {
    ...mapState({
      categories: state => state.docs.categories.list
    }),
    editorOptions() {
      const options = Object.assign({}, defaultOptions, this.options);
      options.initialEditType = this.mode;
      options.height = this.height;
      options.language = this.language;
      return options;
    },
    getCategoriesDropdown() {
      const tree = this.categories
        .filter(x => !x.category_id)
        .map(x => ({
          ...x,
          children: this.getChildren(this.categories, x.id)
        }));
      return this.flatternCategories(tree);
    },
    submitForm() {
      let newForm = JSON.parse(JSON.stringify(this.form));
      try {
        newForm.title = (newForm.title || "").trim();
        newForm.content = this.editor.getMarkdown();
      } catch (error) {}

      return newForm;
    }
  },
  methods: {
    ...mapMutations({
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading",
      OpenError: "OpenError",
      OpenSuccess: "OpenSuccess",
      CreateNewArticle: "DOCS_ADD_ARTICLE"
    }),
    ...mapActions({
      fetchCategories: "fetchCategories"
    }),
    initEditor() {
      this.editor = new Editor({
        el: document.getElementById(this.id),
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        ...this.editorOptions
      });
    },
    flatternCategories(array, result, parentTitle = "") {
      if (!result) {
        result = [];
      }

      array.forEach(e => {
        let found = result.find(item => item.id == e.id);
        if (!found) {
          let title = "";
          if (!parentTitle) {
            title = e.title;
          } else {
            title = parentTitle + ` > ${e.title}`;
          }
          result.push({ id: e.id, title: title });
          if (e.children && e.children.length) {
            return this.flatternCategories(e.children, result, title);
          }
        }
      });

      return result;
    },
    getChildren(array, id) {
      return array
        .filter(x => x.category_id == id)
        .map(x => ({
          ...x,
          children: this.getChildren(array, x.id)
        }));
    },
    recalcDataExport(form = this.form) {
      try {
        let newForm = JSON.parse(JSON.stringify(form));
        this.dataForm = JSON.stringify(newForm, function(key, value) {
          if (typeof value === "object") return JSON.stringify(value);
          return value;
        });
      } catch (error) {
        this.dataForm = null;
      }
    },
    newArticle() {
      this.OpenLoading();
      this.recalcDataExport();
      console.log(this.form.category_id);
      console.log(this.submitForm);
      docsAPI
        .createArticle(this.form.category_id, this.submitForm)
        .then(res => {
          this.CreateNewArticle(res.data.article);
          this.OpenSuccess({
            message: "Create new Article successfully!",
            time: 2000
          });
          this.$router.push({
            name: "Edit-Article",
            params: { articleId: res.data.article.id }
          });
          this.CloseLoading();
        })
        .catch(err => {
          this.CloseLoading();
          this.OpenError({ message: `<p>${err}</p>` });
        });
    }
  },
  created() {
    if (!this.categories || !this.categories.length) {
      this.fetchCategories();
    }

    if (this.categories) {
      this.form.category_id = this.$route.query.categoryId;
    }
  },
  mounted() {
    this.initEditor();
  },
  beforeDestroy() {},
  watch: {
    form: {
      deep: true,
      handler(newV) {
        this.recalcDataExport(newV);
      }
    },
    categories: {
      deep: true,
      handler(newV, oldV) {
        this.form.category_id = parseInt(this.$route.query.categoryId);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.gf_section-2 .main--body .form {
  padding: 4em 0;
}
</style>
